import { useRef, useCallback } from 'react';

import PropTypes from 'prop-types';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Button from '~/components/Button';
import { useImageReader } from '~/hooks/useImageReader';
import api from '~/services/api';

import { Container, Input } from './styles';

const ImportFile = ({ onChange }) => {
  // Lodging
  const { id } = useParams();

  const inputRef = useRef(null);

  const { loadImage } = useImageReader();

  const lodgingTotalImages = useSelector((state) => state.lodgingTotalImages);

  const handleChange = useCallback(
    (event) => {
      const files = [...event.target.files];

      const images = files.map(async (imageFile, index) => {
        const { name, isPortrait, file } = await loadImage(imageFile);

        const current = index !== 0 ? index : 1;
        const totalImages = lodgingTotalImages - 1;
        const order = totalImages + current;

        console.log({ order, lodgingTotalImages, totalImages, current });
        const formData = new FormData();
        formData.append('name', name);
        formData.append('portrait', isPortrait);
        formData.append('order', Number(order));
        formData.append('image', file);

        // Faz o upload da imagem
        try {
          const res = await api.post(`/lodgings/${id}/images`, formData);

          if (res.status === 201) {
            return {
              image: name,
              success: true,
              message: '',
            };
          }

          return {
            image: name,
            success: false,
            message: '',
          };
        } catch (error) {
          return {
            image: name,
            success: false,
            message: error,
          };
        }
      });

      Promise.all(images).then(() => {
        onChange();
      });
    },
    [id, api, loadImage, lodgingTotalImages, onChange]
  );

  const handleClickOpenFinder = () => inputRef.current.click();

  return (
    <Container>
      <Button
        label="Importar"
        iconSize={24}
        icon={<AiOutlineCloudUpload />}
        onClick={handleClickOpenFinder}
      />
      <Input
        ref={inputRef}
        type="file"
        multiple="multiple"
        onChange={handleChange}
      />
    </Container>
  );
};

ImportFile.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default ImportFile;
