/* eslint-disable camelcase */
import {
  useCallback,
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import api from '~/services/api';
import { Creators as LodgingTotalImages } from '~/store/ducks/lodgingTotalImages';

import Card from './Card';
import { Container } from './styles';

const DraggableBoard = (props, ref) => {
  const { token } = useSelector((state) => state.auth);

  // Lodging
  const params = useParams();

  const dispatch = useDispatch();

  const [images, setImages] = useState({
    data: [],
    total: '0',
  });

  const getImages = useCallback(async () => {
    const { id } = params;
    const { data, status } = await api.get(
      `/lodgings/${id}/images?orderBy=order ASC`
    );

    if (status === 200) {
      const total = data.length;

      dispatch(LodgingTotalImages.setLodgingTotalImages(total));
      setImages({ data, total });
    }
  }, [params, dispatch]);

  useEffect(() => {
    getImages();
  }, [getImages]);

  useImperativeHandle(ref, () => ({
    getImages,
  }));

  return (
    <Container>
      {images.data.map(({ id, path_url, ...restImage }) => (
        <Card
          key={id}
          onDelete={getImages}
          data={{ id, path_url: `${path_url}?token=${token}`, ...restImage }}
        />
      ))}
    </Container>
  );
};

export default forwardRef(DraggableBoard);
