import { forwardRef, useImperativeHandle, useState, useRef } from 'react';

import { format, isAfter } from 'date-fns';
import { toast } from 'react-toastify';

import DatePicker from '~/components/Material/DatePicker';
import Input from '~/components/Material/Input';
import Select from '~/components/Material/Select';
import SideForm from '~/components/SideForm';
import FixedControl from '~/components/SideForm/FixedControl';
import Section from '~/components/SideForm/Section';
import Title from '~/components/SideForm/Title';
import api from '~/services/api';

import { Content } from './styles';

const wsStatus = [
  { label: '-- Remover do Filtro --', value: '' },
  { label: 'Aberto', value: 'Aberto' },
  { label: 'Ganho', value: 'Ganho' },
  { label: 'Perdido', value: 'Perdido' },
];

const wsEtapas = [
  { label: '-- Remover do Filtro --', value: '' },
  { label: 'Prospecção', value: 'Prospecção' },
  { label: 'Sondagem', value: 'Sondagem' },
  { label: 'Orçamento', value: 'Orçamento' },
  { label: 'Negociando', value: 'Negociando' },
  { label: 'Pagamento', value: 'Pagamento' },
];

const wsDisponivel = [
  { label: '-- Remover do Filtro --', value: '' },
  { label: 'COM Atendente', value: 'ocupado' },
  { label: 'SEM Atendente', value: 'livre' },
];

const wsPropositos = [
  { label: '-- Remover do Filtro --', value: '' },
  { label: 'Ingresso', value: 'Ingresso' },
  { label: 'Hospedagem', value: 'Hospedagem' },
];

const INITIAL_USERS = [{ label: '-- Remover do Filtro --', value: '' }];

const SearchForm = ({ ...rest }, ref) => {
  const formRef = useRef();
  const buttonRef = useRef();
  const [searchParams, setSearchParams] = useState({});
  const [users, setUsers] = useState(INITIAL_USERS);
  const [usersDisabled, setUsersDisabled] = useState(true);
  const [checkoutMinDate, setCheckoutMinDate] = useState(new Date());
  const [checkoutEnabled, setCheckoutDisabled] = useState(true);

  useImperativeHandle(
    ref,
    () => ({
      setData: async (value) => {
        setSearchParams(value);
        try {
          const response = await api.get('/users', {
            params: {
              active: true,
            },
          });

          setUsers(
            INITIAL_USERS.concat(
              response.data.data.map((resUser) => {
                return { label: resUser.name, value: resUser.id };
              })
            )
          );

          setUsersDisabled(false);
        } catch (error) {
          toast.error('Não foi possível buscar a lista de usuário...');
        }
      },
    }),
    []
  );

  const closeForm = (data) => {
    formRef.current.reset();
    formRef.current.setErrors({});
    setSearchParams({});
    rest.onClose(data);
  };

  const handleSubmit = async (formData) => {
    try {
      buttonRef.current.start();

      if (formData.data_in) {
        formData.data_in = format(formData.data_in, 'yyyy-MM-dd');
      }
      if (formData.data_out) {
        formData.data_out = format(formData.data_out, 'yyyy-MM-dd');
      }

      buttonRef.current.finish({
        type: 'success',
        callback: () => closeForm(formData),
      });

      return 'response';
    } catch (error) {
      buttonRef.current.finish({
        type: 'error',
        autoReset: true,
        timeReset: 2,
      });
      return error;
    }
  };

  const handleCheckinChange = (checkinDate) => {
    if (checkinDate) {
      const checkoutDate = formRef.current.getFieldValue('data_out');

      if (isAfter(checkinDate, checkoutDate)) {
        formRef.current.setFieldValue('data_out', checkinDate);
      }

      setCheckoutMinDate(checkinDate);
      setCheckoutDisabled(false);
    } else {
      formRef.current.setFieldValue('data_out', null);
      setCheckoutDisabled(true);
    }
  };

  const handleOnClose = () => closeForm();

  return (
    <SideForm
      formRef={formRef}
      data={searchParams}
      onClose={handleOnClose}
      onSubmit={handleSubmit}
    >
      <Content>
        <Title label="Filtros de Busca" />
        <Section>
          <Input label="Nome" name="nome" />
          <Input type="email" label="E-mail" name="email" />
          <Input label="Telefone" name="telefone" />

          <Select label="Propósito" name="proposito" items={wsPropositos} />
          <Select
            label="Atendente"
            name="atendente_id"
            items={users}
            disabled={usersDisabled}
          />
          <Select
            label="Disponibilidade do Negócio"
            name="disponibilidade_negocio"
            items={wsDisponivel}
          />
          <Select label="Etapa do Negócio" name="etapa" items={wsEtapas} />
          <Select label="Status do Negócio" name="status" items={wsStatus} />
          <DatePicker
            label="Data Inicial do Negócio"
            name="data_in"
            onChange={handleCheckinChange}
            disableText
            clearable
            variant="dialog"
          />
          <DatePicker
            label="Data Fim do Negócio"
            name="data_out"
            minDate={checkoutMinDate}
            disabled={checkoutEnabled}
            disableText
            clearable
            variant="dialog"
          />
        </Section>

        <FixedControl buttonRef={buttonRef} buttonLabel="Buscar" />
      </Content>
    </SideForm>
  );
};

export default forwardRef(SearchForm);
