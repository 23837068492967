import axios from 'axios';
import { useDispatch } from 'react-redux';

import { Creators as RequestActions } from '~/store/ducks/request';

export const axiosConfig = axios.create({
  baseURL: 'https://thermasparqueaquatico.com.br/WebService/ws/',
  // baseURL: 'http://localhost:8080/WebService/ws/',
  headers: {
    Accept: 'application/json',
  },
});

const useWebService = () => {
  const dispatch = useDispatch();

  // Request interceptor
  axiosConfig.interceptors.request.use(
    (config) => {
      dispatch(RequestActions.setStatusRequest(true));

      if (['GET', 'get'].find((type) => type === config.method)) {
        config.params = {
          ...config.params,
          apikey: 'NjaTVX5S6KVmQpDc2c4wZ309jdTrp120',
        };
      } else {
        config.data.apikey = 'NjaTVX5S6KVmQpDc2c4wZ309jdTrp120';
      }

      return config;
    },
    (error) => {
      dispatch(RequestActions.setStatusRequest(false));
      return Promise.reject(error);
    }
  );

  // Response interceptor
  axiosConfig.interceptors.response.use(
    (response) => {
      dispatch(RequestActions.setStatusRequest(false));

      return response;
    },
    (error) => {
      dispatch(RequestActions.setStatusRequest(false));
      return Promise.reject(error);
    }
  );

  return axiosConfig;
};

export default useWebService;
