import { useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import Select from '~/components/Material/Select';
import api from '~/services/api';

const SelectPerson = () => {
  const [persons, setPersons] = useState();

  useEffect(() => {
    const getTeams = async () => {
      try {
        const {
          data: { data, total },
        } = await api.get(`/persons`);

        if (Number(total) > 0)
          return setPersons(
            data.map(({ id, name }) => ({
              value: id,
              label: name,
            }))
          );

        return setPersons([]);
      } catch (error) {
        toast.error('Erro ao buscar pessoas...');

        return setPersons([]);
      }
    };

    getTeams();
  }, []);

  return (
    <Select label="Pessoa" name="person_id" items={persons} isSearchable />
  );
};

export default SelectPerson;
