// import PropTypes from 'prop-types';
import { MdEdit } from 'react-icons/md';

import CircleButton from '~/components/CircleButton';

const DescriptionButton = () => {
  return <CircleButton tooltip="Descrição da imagem" icon={<MdEdit />} />;
};

// DescriptionButton.propTypes = {
//   icon: PropTypes.element.isRequired,
// };

export default DescriptionButton;
