import {
  forwardRef,
  useContext,
  useImperativeHandle,
  useState,
  useRef,
} from 'react';

import { Scope } from '@unform/core';

import Checkbox from '~/components/Material/Checkbox';
import Input from '~/components/Material/Input';
import Select from '~/components/Material/Select';
import ModalDeleteContext from '~/components/ModalDelete/ModalContext';
import SideForm from '~/components/SideForm';
import DangerZone from '~/components/SideForm/DangerZone';
import FixedControl from '~/components/SideForm/FixedControl';
import Section from '~/components/SideForm/Section';
import SubTitle from '~/components/SideForm/SubTitle';
import Title from '~/components/SideForm/Title';
import api from '~/services/api';
import ufs from '~/utils/ufs';
import yupValidate from '~/utils/yupValidate';
import { storeSchema } from '~/validators/person.schemas';

import { Content } from './styles';

const Form = ({ ...rest }, ref) => {
  const { openModalDelete } = useContext(ModalDeleteContext);

  const formRef = useRef();
  const buttonRef = useRef();
  const [person, setPerson] = useState({});

  useImperativeHandle(
    ref,
    () => ({
      setData: (value) => setPerson(value),
    }),
    []
  );

  const closeForm = (reload) => {
    formRef.current.setErrors({});
    formRef.current.reset();
    setPerson({});
    rest.onClose(reload);
  };

  const storePerson = async (data) => {
    try {
      buttonRef.current.start();
      // Dados básicos
      const response = await api.post('/persons', data);

      if (response.status === 201) {
        buttonRef.current.finish({
          type: 'success',
          callback: () => closeForm(true),
        });
      }

      return 'response';
    } catch (error) {
      // if (error.response) {
      //   const { status } = error.response;

      //   if (status === 400) {
      //     return error;
      //   }
      // }
      buttonRef.current.finish({
        type: 'error',
        autoReset: true,
        timeReset: 2,
      });
      return error;
    }
  };

  const updatePerson = async (data) => {
    try {
      buttonRef.current.start();
      // Dados básicos
      const response = await api.put(`/persons/${person.id}`, data);

      if (response.status === 200) {
        buttonRef.current.finish({
          type: 'success',
          callback: () => closeForm(true),
        });
      }
      return 'response';
    } catch (error) {
      // if (error.response) {
      //   const { status } = error.response;

      //   if (status === 400) {
      //     return error;
      //   }
      // }

      buttonRef.current.finish({
        type: 'error',
        autoReset: true,
        timeReset: 2,
      });
      return error;
    }
  };

  const handleChangeType = (value) => {
    setPerson((currentData) => ({ ...currentData, type: value }));
  };

  const handleSubmit = async (formData) => {
    // Limpa os erros
    formRef.current.setErrors({});

    // Valida os campos
    const { success, data, errors } = await yupValidate(storeSchema, {
      ...formData,
    });

    if (!success) {
      return formRef.current.setErrors(errors);
    }

    if (person.id) {
      return updatePerson(data);
    }

    return storePerson(data);
  };

  const handleOnClose = () => closeForm(false);

  const handleDelete = () => {
    openModalDelete({
      url: `/persons/${person.id}`,
      title: 'Deseja deletar a pessoa?',
      onDelete: () => closeForm(true),
      autoClose: true,
      messages: {
        success: 'Sucesso ao deletar a pessoa!',
        error: 'Erro ao deletar a pessoa...',
      },
    });
  };

  return (
    <SideForm
      formRef={formRef}
      data={person}
      onClose={handleOnClose}
      onSubmit={handleSubmit}
    >
      <Content>
        <Title
          label={person.id ? `Editando pessoa - ${person.id}` : 'Nova pessoa'}
        />
        <Section>
          <SubTitle label="Dados básicos" />
          {person.id === undefined || person.type === 'C' ? (
            <Select
              name="type"
              label="Tipo"
              onChange={handleChangeType}
              items={[
                { value: 'C', label: 'Contato' },
                { value: 'F', label: 'Pessoa física' },
                { value: 'J', label: 'Pessoa jurídica' },
              ]}
            />
          ) : null}

          <Input label="Nome" name="name" />
          <Input name="phone" label="Celular" mask="cellphone" returnUnmasked />
          <Checkbox name="whatsapp" label="É whatsapp?" />

          <Input type="email" label="E-mail" name="email" />
        </Section>
        {person.type !== 'C' && (
          <Section>
            <SubTitle label="Documentos" />
            {person.type === 'F' ? (
              <Scope path="personPhysic">
                <Input label="RG" name="rg" mask="rg" returnUnmasked />
                <Input label="CPF" name="cpf" mask="cpf" returnUnmasked />
              </Scope>
            ) : (
              <Scope path="personJuristic">
                <Input label="CNPJ" name="cnpj" mask="cnpj" returnUnmasked />
                <Input
                  label="Inscrição estadual (opcional)"
                  name="state_registration"
                />
                <Input
                  label="Inscrição municipal (opcional)"
                  name="city_registration"
                />
              </Scope>
            )}
          </Section>
        )}
        <Section>
          <SubTitle label="Endereço (opcional)" />
          <Input name="address" label="Rua" />
          <Input name="number" label="Número" />
          <Input name="neighborhood" label="Bairro" />
          <Input name="city" label="Cidade" />
          <Select name="state" label="Estado" items={ufs} />
        </Section>
        {person.id && (
          <DangerZone message="Deletar pessoa" onClick={handleDelete} />
        )}
        <FixedControl buttonRef={buttonRef} />
      </Content>
    </SideForm>
  );
};

export default forwardRef(Form);
