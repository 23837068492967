import { useCallback, useEffect, useRef, useState } from 'react';

import formatDistance from 'date-fns/formatDistance';
import pt from 'date-fns/locale/pt';
import * as FaIcons from 'react-icons/fa';
import { toast } from 'react-toastify';

import Actions from '~/components/Actions';
import Button from '~/components/Button';
import Header from '~/components/Header';
import Pagination from '~/components/Pagination';
import Table from '~/components/Table';
import TableActions from '~/components/TableActions';
import PrivateLayout from '~/layouts/Private';
import api from '~/services/api';

import Form from './Form';
import { Container, BoxIcon } from './styles';

const INITIAL_DETAILS = {
  data: [],
  page: 0,
  perPage: 0,
  total: 0,
  lastPage: 0,
};

const ConfigDetail = () => {
  const formRef = useRef();

  const [details, setDetails] = useState(INITIAL_DETAILS);

  const getDetails = useCallback(async (page = 1) => {
    try {
      const { data } = await api.get(
        `details?page=${page}&perPage=10&active=true&orderBy=updated_at DESC`
      );

      console.log(data);

      setDetails(data);
    } catch (error) {
      toast.error('Erro ao buscar detalhes...');
      setDetails(INITIAL_DETAILS);
    }
  }, []);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  const handleClickOpenForm = () =>
    formRef.current.setData({
      name: '',
    });

  const handleClickEdit = (data) => formRef.current.setData(data);

  const handleOnCloseForm = (reload) => {
    if (reload) getDetails(1);
  };

  const columns = [
    {
      key: 'icon_name',
      label: 'Ícone',
      format: (value) => {
        const FaIcon = FaIcons[value];
        return (
          <BoxIcon>
            <FaIcon />
          </BoxIcon>
        );
      },
    },
    {
      key: 'name',
      label: 'Nome',
    },
    {
      key: 'is_boolean',
      label: 'Tipo',
      format: (value) => (value ? 'Sim ou não' : 'Quantitativo'),
    },
    {
      key: 'updated_at',
      label: 'Atualizado',
      format: (value) =>
        formatDistance(new Date(value), new Date(), {
          locale: pt,
          addSuffix: true,
        }),
    },
    {
      label: '',
      width: 50,
      format: (detail) => {
        return <TableActions onEdit={() => handleClickEdit(detail)} />;
      },
    },
  ];

  return (
    <PrivateLayout>
      <Container>
        <Header title="Detalhes" total={details.total} />
        <Actions align="flex-end">
          <Button label="Novo detalhe" onClick={handleClickOpenForm} />
        </Actions>
        <Form ref={formRef} onClose={handleOnCloseForm} />
        <Table columns={columns} rows={details.data} />
        <Pagination
          onChange={getDetails}
          page={details.page}
          perPage={details.perPage}
          total={details.total}
          lastPage={details.lastPage}
          position="flex-end"
        />
      </Container>
    </PrivateLayout>
  );
};

export default ConfigDetail;
