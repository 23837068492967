import { useCallback, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import api from '~/services/api';

import { Container } from './styles';

const View = () => {
  const { id } = useParams();

  const [lodging, setLodging] = useState({});

  const getLodging = useCallback(async () => {
    try {
      const response = await api.get(`/lodgings/${id}`);

      const { data } = response;
      console.log(data);
      setLodging(data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    getLodging();
  }, [getLodging]);

  return (
    <Container>
      {lodging?.name} - {id}
    </Container>
  );
};

export default View;
