import {
  forwardRef,
  useImperativeHandle,
  useState,
  useRef,
  useCallback,
} from 'react';

import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Autocomplete from '~/components/Material/Autocomplete';
import Input from '~/components/Material/Input';
import Select from '~/components/Material/Select';
import RadioGroup from '~/components/RadioGroup';
import SideForm from '~/components/SideForm';
import FixedControl from '~/components/SideForm/FixedControl';
import Section from '~/components/SideForm/Section';
import Title from '~/components/SideForm/Title';
import useWebService from '~/hooks/useWebService';
import yupValidate from '~/utils/yupValidate';
import { createSchema } from '~/validators/lead.schemas';

import { Content } from './styles';

const wsPropositos = [
  { label: 'Ingresso', value: 'Ingresso' },
  { label: 'Hospedagem', value: 'Hospedagem' },
];

const LeadForm = ({ ...rest }, ref) => {
  const formRef = useRef();
  const buttonRef = useRef();
  const [data, setData] = useState({});
  const [search, setSearch] = useState(true);
  const [searching, setSearching] = useState(false);
  const [leads, setLeads] = useState([]);
  const [searchTimer, setSearchTimer] = useState();
  const user = useSelector((state) => state.user);
  const ws = useWebService();

  useImperativeHandle(
    ref,
    () => ({
      setData: async (value) => setData(value),
    }),
    []
  );

  const handleRadioChange = ({ value }) => {
    setSearch(value === 'true');
  };

  const closeForm = (leadData) => {
    formRef.current.reset();
    formRef.current.setErrors({});
    setData({});
    rest.onClose(leadData);
  };

  const toastError = (message) => {
    toast.error(message);
    buttonRef.current.finish({
      type: 'error',
      autoReset: true,
      timeReset: 2,
    });
  };

  const handleAutocompleteChange = useCallback(
    (event) => {
      clearTimeout(searchTimer);

      const { value } = event.target;

      if (value && value.length >= 3) {
        const newTimer = setTimeout(async () => {
          try {
            setSearching(true);
            const response = await ws.get('atendimento/retornaLeads.php', {
              params: { nome: value, telefone: value, email: value },
            });

            if (response.data.result) {
              setLeads(response.data.dados);
            }
          } catch (error) {
            toast.error('Não foi possível buscar a lista de usuário...');
          } finally {
            setSearching(false);
          }
        }, 500);

        setSearchTimer(newTimer);
      } else {
        setLeads([]);
      }
    },
    [searchTimer]
  );

  const handleSubmit = async (formData) => {
    try {
      buttonRef.current.start();

      // Valida os campos
      const { success, data: validData, errors } = await yupValidate(
        createSchema,
        {
          ...formData,
        }
      );

      if (!success) {
        toastError('Dados inválidos...');
        return formRef.current.setErrors(errors);
      }

      const response = await ws.post('atendimento/insereAtendimento.php', {
        ...validData,
        atendente_id: user.id,
        atendente_nome: user.name,
      });

      if (response.data.result) {
        buttonRef.current.finish({
          type: 'success',
          callback: () => closeForm(response.data.dados),
        });
      } else {
        toastError(response.data.dados || 'Erro na API ao inserir lead...');
      }

      return 'response';
    } catch (error) {
      toastError('Erro ao inserir lead...');
      return error;
    }
  };

  const handleOnClose = () => closeForm(false);

  return (
    <SideForm
      formRef={formRef}
      data={data}
      onClose={handleOnClose}
      onSubmit={handleSubmit}
    >
      <Content>
        <Title label="Novo Negócio" />
        <Section>
          <RadioGroup
            label=""
            name="is_search"
            direction="row"
            items={[
              { value: true, label: 'Pesquisar Lead' },
              { value: false, label: 'Novo Lead' },
            ]}
            onChange={handleRadioChange}
          />

          {search ? (
            <Autocomplete
              name="client_id"
              options={leads}
              optionKeyLabel="label"
              optionKeyValue="id"
              loading={searching}
              onInputChange={handleAutocompleteChange}
            />
          ) : (
            <>
              <Input label="Nome" name="nome" />
              <Input
                mask="phoneOrCellphone"
                label="Telefone"
                name="telefone"
                type="tel"
              />
              <Input label="Email" name="email" type="email" />
            </>
          )}

          <Select label="Propósito" name="proposito" items={wsPropositos} />
        </Section>

        <FixedControl buttonRef={buttonRef} buttonLabel="Salvar" />
      </Content>
    </SideForm>
  );
};

export default forwardRef(LeadForm);
