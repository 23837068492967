import PropTypes from 'prop-types';

import CoverButton from './CoverButton';
import DescriptionButton from './DescriptionButton';
import PublicButton from './PublicButton';
import { Container } from './styles';

const Controls = ({ data }) => {
  const { id, portrait } = data;

  return (
    <Container isPortrait={portrait}>
      <CoverButton imageId={id} />
      <DescriptionButton />
      <PublicButton data={data} />
    </Container>
  );
};

Controls.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    portrait: PropTypes.bool.isRequired,
  }).isRequired,
};

export default Controls;
