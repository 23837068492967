import { ReactComponent as LogoSVG } from '~/assets/svg/logo.svg';

import { Container } from './styles';

const Logo = () => {
  return (
    <Container>
      <LogoSVG />
    </Container>
  );
};

export default Logo;
